import { Table, Input, Button, Form } from "antd";
import ReactToPrint from "react-to-print";
import { useEffect, useRef, useState } from "react";

import logo from "../assets/logo/logo.png";

export default function Home() {
  const [dataSource, setDataSource] = useState([]);
  const [quotationTo, setQuotationTo] = useState({});
  const [quotationNo, setQuotationNo] = useState("CS00152");
  const [quotationDate, setQuotationDate] = useState(
    new Date().toLocaleDateString("en-GB")
  );

  const columns = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      width: "10%",
    },
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: "10%",
      render: (text) => <>{text.toLocaleString("en-IN")}</>,
    },
  ];

  const componentRef = useRef();
  const [subTotal, setSubTotal] = useState(0);
  const discount = 0;

  const [form] = Form.useForm();

  useEffect(() => {
    const totalPrice = dataSource
      .map((item) => Number(item.price))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    setSubTotal(totalPrice);
  }, [dataSource]);

  const handleAddItem = (values) => {
    const newKey = dataSource.length + 1;
    const newItem = {
      key: newKey,
      no: newKey,
      item: values.item,
      price: values.price,
    };
    setDataSource([...dataSource, newItem]);
    form.resetFields();
  };

  return (
    <div className="App">
      <div style={{ padding: "80px" }}>
        <h2>Add Item to Quotation</h2>
        <Form
          form={form}
          layout="inline"
          onFinish={handleAddItem}
          style={{ marginBottom: "20px" }}
        >
          <Form.Item
            name="item"
            rules={[{ required: true, message: "Please input the item name!" }]}
          >
            <Input placeholder="Item Name" />
          </Form.Item>
          <Form.Item
            name="price"
            rules={[{ required: true, message: "Please input the price!" }]}
          >
            <Input
              placeholder="Price"
              type="number"
              // min={0} step={500}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Item
            </Button>
          </Form.Item>
        </Form>

        <div style={{ textAlign: "start" }}>
          <h6 style={{ margin: "5px 0" }}>Quotation To:</h6>
          <Input
            value={quotationTo.name}
            onChange={(e) =>
              setQuotationTo({ ...quotationTo, name: e.target.value })
            }
            placeholder="Name"
            style={{ marginBottom: "5px" }}
          />
          <Input
            value={quotationTo.company}
            onChange={(e) =>
              setQuotationTo({ ...quotationTo, company: e.target.value })
            }
            placeholder="Company"
            style={{ marginBottom: "5px" }}
          />
          <Input
            value={quotationTo.contact}
            onChange={(e) =>
              setQuotationTo({ ...quotationTo, contact: e.target.value })
            }
            placeholder="Contact"
          />
        </div>

        <div style={{ textAlign: "start" }}>
          <h3 style={{ margin: "5px 0" }}>QUOTATION</h3>
          <h6 style={{ margin: "5px 0" }}>
            Quotation No:{" "}
            <Input
              value={quotationNo}
              onChange={(e) => setQuotationNo(e.target.value)}
              style={{
                width: "auto",
                display: "inline-block",
                marginLeft: "5px",
              }}
            />
          </h6>
          <h6 style={{ margin: "5px 0" }}>
            Quotation Date:{" "}
            <Input
              value={quotationDate}
              onChange={(e) => setQuotationDate(e.target.value)}
              style={{
                width: "auto",
                display: "inline-block",
                marginLeft: "5px",
              }}
            />
          </h6>
        </div>
      </div>

      <ReactToPrint
        trigger={() => (
          <button className="print-btn spl" onClick={() => window.print()}>
            Print
          </button>
        )}
        content={() => componentRef.current}
      />
      <div
        ref={componentRef}
        style={{
          fontFamily: "Montserrat, sans-serif",
          height: "842px",
        }}
      >
        <div className="header-container">
          <div style={{ textAlign: "start" }}>
            <img src={logo} alt="codescap-logo" className="quote-logo" />
            <h6 style={{ margin: "5px" }}>Phone: +91 9037162751</h6>
            <h6 style={{ margin: "5px" }}>Email: support@codescap.com</h6>
          </div>

          <div style={{ textAlign: "start" }}>
            <h3 style={{ margin: "5px 0" }}>QUOTATION</h3>
            <h6 style={{ margin: "5px 0" }}>
              Quotation No:{" "}
              <span style={{ color: "#c22328" }}>{quotationNo}</span>
            </h6>
            <h6 style={{ margin: "5px 0" }}>
              Quotation Date:{" "}
              <span style={{ color: "#c22328" }}>{quotationDate}</span>
            </h6>
          </div>
        </div>
        <div className="items-container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ textAlign: "start" }}>
              <h6 style={{ margin: "5px 0" }}>Quotation To:</h6>
              <h4 style={{ margin: "5px 0" }}>{quotationTo.name}</h4>
              <p style={{ margin: "5px 0" }}>{quotationTo.company}</p>
              <p style={{ margin: "5px 0" }}>{quotationTo.contact}</p>
            </div>
            <div style={{ textAlign: "start" }}>
              <h6 style={{ margin: "5px 0" }}>Quotation From:</h6>
              <h4 style={{ margin: "5px 0" }}>Codescap IT Solutions</h4>
              <p style={{ margin: "5px 0" }}>Roshni Plaza</p>
              <p style={{ margin: "5px 0" }}>Angadippuram</p>
            </div>
          </div>
          <div>
            <div style={{ margin: "20px 0" }}>
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
              />
              <span className="amtMentioned">
                All the amounts mentioned are in INR
              </span>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                margin: "20px 0",
                textAlign: "start",
                width: "142px",
              }}
            >
              <h6 className="payment-details-h6">
                Acc No: <span>99980101054273</span>
              </h6>
              <h6 className="payment-details-h6">
                IFSC: <span>FDRL0001537</span>
              </h6>
              <h6 className="payment-details-h6">
                UPI: <span style={{ color: "#c22328" }}>90371 62751</span>
              </h6>
            </div>
            <div
              style={{
                margin: "20px 0",
                textAlign: "start",
                width: "142px",
                justifyContent: "flex-end",
              }}
            >
              <h6 style={{ margin: "5px 0" }}>
                Subtotal: <span>{subTotal.toLocaleString("en-IN")}/-</span>
              </h6>
              <h6 style={{ margin: "5px 0" }}>
                Discount: <span>{discount === 0 ? "" : `${discount}/-`}</span>
              </h6>
              <h3 style={{ margin: "5px 0" }}>
                Total:{" "}
                <span style={{ color: "#c22328" }}>
                  {(subTotal - discount).toLocaleString("en-IN")}/-
                </span>
              </h3>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "12px 0",
            background: "#ecdada",
          }}
        >
          <p style={{ textAlign: "center", margin: 0, fontSize: "13px" }}>
            A subsidiary of Foodoor Enterprises LLP
          </p>
        </div>
      </div>
    </div>
  );
}
